<template>
	<section class="gardenManagementPage">
		<g-breadcrumb bread="用户管理" />
		<TableContainer title="用户管理">
			<template slot="button">
				<el-button type="success" @click="addTheme" class="turnBule">添加用户</el-button>
			</template>
			<el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
				:cell-style="{ textAlign: 'center' }">
				<!-- <el-table-column prop="id" label="id" min-width="160" /> -->
				<el-table-column prop="contactPhone" label="账号" min-width="60" />
				<el-table-column prop="fullName" label="昵称" min-width="130" />
				<el-table-column prop="createTime" label="创建日期" min-width="100" />
				<el-table-column width="100" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden(scope.row)">编辑</el-button>
						<!-- <el-button type="text" class="textOrange" @click="textOrange(scope.row)">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
				:size.sync="searchForm.size" @pagination="fetchData" />
		</TableContainer>

		<GDialog :dialog.sync="themeDialog" @btnSure="submitThemeForm('ref_themeForm')"
			@btnClose="resetThemeForm('ref_themeForm')">
			<el-form ref="ref_themeForm" :model="themeForm" :rules="rules" label-width="100px" @submit.native.prevent>
				<el-form-item label="登录账号" prop="contactPhone">
					<el-input v-model="themeForm.contactPhone" maxlength="35" placeholder="请输入登录账号" />
				</el-form-item>
				<el-form-item label="用户名" prop="fullName">
					<el-input v-model="themeForm.fullName" maxlength="35" placeholder="请输入用户名" />
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input placeholder="请输入密码" :type="flagType" v-model="themeForm.password">
						autocomplete="off"
						<i slot="suffix" :class="[this.flag?'iconfont el-icon-light-rain':'iconfont el-icon-sunny']"
							style="margin-top:8px;font-size:18px;" @click="getFlag()" />
					</el-input>
				</el-form-item>
				<el-form-item label="角色" prop="roleId">
					<el-radio-group v-model="themeForm.roleId" @change="change">
						<el-radio :label="item.id" :key="item.id" v-for="item in role">{{item.roleName}}</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
		</GDialog>
		<GDialog :dialog.sync="themeDialog1" @btnSure="submitThemeForm1('ref_themeForm')"
			@btnClose="resetThemeForm('ref_themeForm')">
			<el-form ref="ref_themeForm" :model="themeForm" :rules="rules" label-width="100px" @submit.native.prevent>
				<el-form-item label="登录账号" prop="contactPhone">
					<el-input v-model="themeForm.contactPhone" maxlength="35" placeholder="请输入登录账号" />
				</el-form-item>
				<el-form-item label="用户名" prop="fullName">
					<el-input v-model="themeForm.fullName" maxlength="35" placeholder="请输入用户名" />
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input placeholder="请输入密码" :type="flagType" v-model="themeForm.password">
						autocomplete="off"
						<i slot="suffix" :class="[this.flag?'iconfont el-icon-light-rain':'iconfont el-icon-sunny']"
							style="margin-top:8px;font-size:18px;" @click="getFlag()" />
					</el-input>
				</el-form-item>
				<el-form-item label="角色" prop="roleId">
					<el-radio-group v-model="themeForm.roleId" @change="change">
						<el-radio :label="item.id" :key="item.id" v-for="item in role">{{item.roleName}}</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
		</GDialog>

	</section>

</template>

<script>
	import {
		findUser,
		addUser,
		editUser,
		delUser,
		selectRule
	} from '@api/administratorAction/api_administratorAction';
	export default {
		data() {
			return {
				flag: false,
				flagType: 'password',
				tableData: [],
				role: [],
				total: 0,
				isCheck: false,
				searchForm: {
					page: 1,
					size: 10
				},
				themeDialog: {
					title: '标题哦',
					visible: false
				},
				themeDialog1: {
					title: '标题哦',
					visible: false
				},
				themeForm: {
					contactPhone: '',
					fullName: '',
					password: '',
					roleId: 0,
				},
				rules: {
					contactPhone: [{
						required: true,
						message: '请输入管理账号',
						trigger: 'blur'
					}],
					fullName: [{
							required: true,
							message: '请输入用户名',
							trigger: 'blur'
						},
						// { min: 3, max: 35, message: '请输入用户性别', trigger: 'blur' }
					],
					password: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						// { min: 3, max: 35, message: '请输入用户性别', trigger: 'blur' }
					],
				},
				defaultProps: {
					children: "children",
					label: "menuName",
				},
			}
		},
		created() {
			this.findUser()
		},
		methods: {
			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},
			getFlag() {
				this.flag = !this.flag
				this.flagType = this.flag ? 'text' : 'password' //text为显示密码；password为隐藏密码
			},
			findUser() {
				// let params = {
				// 	size: 10,
				// 	page: 1
				// }
				findUser(this.searchForm).then(res => {
					this.tableData = res.records
					this.total = res.total
				})
			},
			fetchData() {
				this.findUser()
			},
			change(row) {
				console.log(row, 111)
				this.themeForm.roleId = row;
			},
			editGarden(row) {
				console.log(row)
				this.themeDialog1.visible = true
				this.themeDialog1.title = '编辑用户'
				this.selectRule()
				this.themeForm = row


			},
			selectRule() {
				let params = {
					size: 20,
					page: 1
				}
				selectRule(params).then((res) => {
					console.log(res)
					this.role = res
					// this.list.push({
					// 	children: res.children,
					// 	label: res.menuName,
					// 	// children: this.fun1(res)
					// })
				})
			},

			addTheme() {
				this.themeForm = [];
				this.themeDialog.visible = true;
				this.themeDialog.title = "添加用户"
				this.selectRule()

			},
			submitThemeForm(formName) {
				this.$refs[formName].validate(valid => {
					console.log(valid, 1111)
					if (!valid) {

						return false
					}
					const params = {
						...this.themeForm
					}
					// let _api
					// params.materialId = Number(this.activeTabsName)
					// if (this.themeDialog.title === '添加主题') {
					// 	_api = addTheme
					// } else {
					// 	if (this.currentThemeRow.themeName === params.themeName) {
					// 		this.$message.error('主题名称未作修改！')
					// 		return false
					// 	}
					// 	_api = updateTheme
					// 	params.themeId = this.currentThemeRow.id
					// }
					addUser(params).then(res => {
						this.$message.success(this.themeDialog.title + '成功')
						this.resetThemeForm('ref_themeForm')
						this.findUser()
						// this.$refs.ref_addCourse.getOptions()
					})

				})

			},
			submitThemeForm1(formName) {
				this.$refs[formName].validate(valid => {
					if (!valid) {

						return false
					}
					const params = {
						...this.themeForm
					}
					// let _api
					// params.materialId = Number(this.activeTabsName)
					// if (this.themeDialog.title === '添加主题') {
					// 	_api = addTheme
					// } else {
					// 	if (this.currentThemeRow.themeName === params.themeName) {
					// 		this.$message.error('主题名称未作修改！')
					// 		return false
					// 	}
					// 	_api = updateTheme
					// 	params.themeId = this.currentThemeRow.id
					// }
					editUser(params).then(res => {
						this.$message.success(this.themeDialog1.title + '成功')
						this.resetThemeForm('ref_themeForm')
						this.findUser()
						// this.$refs.ref_addCourse.getOptions()
					})

				})

			},
			resetThemeForm(formName) {
				this.themeDialog.visible = false
				this.themeDialog1.visible = false
			}

		}
	}
</script>

<style>
	/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
	.turnBule {
		color: #fff;
		background-color: #58e3e8;
		border-color: rgba(56, 185, 190, 0.12);
	}
	
	.turnBule:hover,
	.turnBule:focus {
		background: rgba(56, 185, 190, 0.5);
		border-color: rgba(56, 185, 190, 0.5);
		color: #fff;
	}
	
	.el-button--text.reviewed{
		color: #38b9be;
	}
	
	.reviewed {
		color: #38b9be;
	}
	
	.el-tree-node__content {
		z-index: 999;
		padding-left: 40px;

	}
</style>
